import React from 'react';
import Zoom from 'react-medium-image-zoom';
// import 'react-medium-image-zoom/dist/styles.css';
import '../../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import 'react-medium-image-zoom/dist/styles.css';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import { convertPxToRem } from '../../helper';
import { Modal, Row } from 'antd';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, styled } from '@mui/material';
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon, Close as CloseIcon } from '@mui/icons-material';

const StyledGalleryWrapper = styled('div')(({ }) => ({
  '& .MuiSvgIcon-root': {
    'color': "black",
    'height': '30px',
    'width': ' 30px',
    'borderRadius': '30px',
    'background': 'white',
    'padding': '2px'
  },
  '& .image-gallery': {
    height: '100%',
  },
  '& .image-gallery-content': {
    height: '100%',
  },
  '& .image-gallery-slide-wrapper ': {
    height: '85%'
  },
  '& .image-gallery-swipe': {
    height: '100%'
  },
  '& .image-gallery-slides': {
    height: '100%'
  },
  '& .image-gallery-slide': {
    height: '100%'
  },
  '& .image-gallery-image': {
    height: '100%'
  }
}));

const ZoomWrapper = styled('div')(({ theme }) => ({
  '& .react-transform-component': {
    [theme.breakpoints.down('md')]: {
      height: '100vh !important'
    }
  }
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    '& .MuiDialogContent-root': {
      overflowY: 'hidden'
    },
    '& .MuiDialog-paper': {
      maxWidth: '65% !important',
      maxHeight: 'calc(100% - -246px) !important',
      // marginTop: '320px'
    },
    '& .MuiDialog-container': {
      overflowY: 'auto !important',
    },
    '& img': {
      maxHeight: '800px',
      maxWidth: '800px'
    },
  },
  '& .MuiDialogContent-root': {
    overflowY: 'hidden',
    padding: '0 !important'
  }
}));

const renderImage = (item) => {
  return (
    <Zoom>
        <ZoomWrapper>
          <Row justify="center">
            <TransformWrapper>
              <TransformComponent>
                <img
                  src={item.original}
                  alt={item.original}
                  style={{
                    width: '100%',
                    // maxHeight: '810px',
                    height: '100%',
                    // maxWidth: '800px'
                    cursor: "zoom-in"
                  }}
                />
              </TransformComponent>
            </TransformWrapper>
          </Row>
        </ZoomWrapper>
    </Zoom>
  );
};

class ImageSliderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.myImageRef = React.createRef();
    this.onFullscreen = this.onFullscreen.bind(this);
    this.onPlay = this.onPlay.bind(this);
    this.onPause = this.onPause.bind(this);
    this.onMoveToSlide = this.onMoveToSlide.bind(this);
    this.showThumbnails = props.showThumbnails;
    this.showBullets = props.showBullets;
    this.fullScreen = props.fullScreen
    this.state = {
      images: [],
      clickedImage: null,
      showModal: false,
      imageSize: 500
    };
  }

  onFullscreen() {
    this.myImageRef.current.fullScreen();
  }
  onPlay() {
    this.myImageRef.current.play();
  }
  onPause() {
    this.myImageRef.current.pause();
  }
  onMoveToSlide() {
    // Index start from 0 so 2 will move to 3rd slide
    this.myImageRef.current.slideToIndex(2);
  }
  componentDidMount() { }

  onImgLoad(target) {
    this.setState({ imageSize: target?.nativeEvent?.srcElement?.naturalWidth || 500 });
  }

  handleImageClick = (event, data) => {
    const clickedImage = event.target;
    this.setState({
      showModal: true,
      clickedImage
    });
  };

  onClose = () => {
    this.setState({
      showModal: false
    });
  }

  render() {
    let images = this.props.images?.map((data, i) => ({id: i, original: data.image, thumbnail: data.image}))||[];
    let selectedId = images.filter((data) => data.original === this.state.clickedImage?.src)?.[0]?.id;
    let orderChangedImages = selectedId ? [...images.slice(selectedId), ...images.slice(0, selectedId)]: [...images];
    return (
      <StyledGalleryWrapper style={{ border: "2px solid #fff", borderRadius: "5px",cursor:'pointer', ...this.props.style, height: "85%", marginRight: "10px" }} className="gallery-wrapper">
        <ImageGallery 
          items={images}
          ref={this.myImageRef}
          onClick={(e, data)=>this.handleImageClick(e, data)}
          lazyLoad={true}
          showFullscreenButton={false}
          showPlayButton={false}
          showThumbnails={this.showThumbnails || false}
          showBullets={this.showBullets || false}
          renderLeftNav={(onClick, disabled) => (
            <Button color='primary' onClick={onClick} disabled={disabled} style={{ position: 'absolute', bottom: '40%', left: '5px', zIndex: '999', borderRadius: '50px' }}>
              <ArrowBackIcon className='arrowIcon' />
            </Button>
          )}
          renderRightNav={(onClick, disabled) => (
            <Button color='primary' onClick={onClick} disabled={disabled} style={{ position: 'absolute', bottom: '40%', right: '5px', zIndex: '999', borderRadius: '50px' }}>
              <ArrowForwardIcon className='arrowIcon' />
            </Button>
          )} 
        />
        <BootstrapDialog fullScreen={this.fullScreen} open={this.state.showModal} onClose={this.onClose}>

          <IconButton
            aria-label="close"
            onClick={this.onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#000',
              zIndex: '999',
              backgroundColor: 'white'
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: "80vh" }}>
              {this.state.clickedImage && (
                <ImageGallery 
                  additionalClass='image-gallery-slide-modal'
                  items={orderChangedImages}
                  showThumbnails={false}
                  renderItem={renderImage}
                  ref={this.myImageRef}
                  lazyLoad={true}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  showBullets={this.showBullets || false}
                  renderLeftNav={(onClick, disabled) => (
                    <Button color='primary' onClick={onClick} disabled={disabled} style={{ position: 'absolute', bottom: '40%', left: '5px', zIndex: '999', borderRadius: '50px' }}>
                      <ArrowBackIcon className='arrowIcon' />
                    </Button>
                  )}
                  renderRightNav={(onClick, disabled) => (
                    <Button color='primary' onClick={onClick} disabled={disabled} style={{ position: 'absolute', bottom: '40%', right: '5px', zIndex: '999', borderRadius: '50px' }}>
                      <ArrowForwardIcon className='arrowIcon' />
                    </Button>
                  )} 
                />
              )}
          </DialogContent>
        </BootstrapDialog>

      </StyledGalleryWrapper>
    );
  }
}
export default ImageSliderComponent;
